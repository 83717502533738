<template>
  <div class="container home3" id="sport_bet">
    <div class="row">
      <maintop1 ref="newmesstop" />
      <maintop2 />
      <div
        :class="
          flag_nav ? 'main_fix_offset col-xl-10' : 'main_fix_width col-xl-12'
        "
        class="mt-4"
      ></div>
      <Footer />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import maintop1 from "./Main_top1.vue";
import maintop2 from "./Main_top2.vue";
import Footer from "./Home/Footer.vue";

export default {
  name: "sport",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["flag_nav"]),
  },
  components: {
    Footer,
    maintop1,
    maintop2,
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
